import { useEffect, useRef, useState } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { Combobox } from '@headlessui/react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { classNames } from '../../../utilities/classNames';
import authenticatedFetcher from '../../../data/authenticatedFetcher';
import { LoadingIndicator } from '../../Common/LoadingIndicator';

interface Props<T> {
  label: string;
  required?: boolean;
  fetchUrl: (query?: string) => string;
  onChange: (selectedOption?: T) => void;
  bindValue: (value: T) => string | undefined;
  bindLabel: (value: T) => string;
  // Used for appending elements such as icons on the right of each selectable item's label
  labelContent?: (item: T) => JSX.Element;
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  queryMinLength?: number;
  constructFallbackOption?: (query: string) => T & {
    onSelect: (
      selectOption: (option: T) => void,
      query: string,
    ) => void | Promise<void>;
  };
}

export function ComboboxInput<T>({
  label,
  onChange,
  required,
  fetchUrl,
  bindValue,
  bindLabel,
  labelContent,
  placeholder,
  defaultValue,
  disabled,
  queryMinLength,
  constructFallbackOption,
}: Props<T>): JSX.Element {
  const { t } = useTranslation('translation', {
    keyPrefix: 'formFields.comboboxInput',
  });
  const ref = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState('');
  const [selectedOption, setSelectedOption] = useState<T | null>(null);

  const { data: options, isValidating } = useSWR<T[] | null>(
    () =>
      (!queryMinLength || query.length >= queryMinLength || defaultValue) &&
      fetchUrl(query || undefined),
    authenticatedFetcher,
    {
      fallbackData: [],
    },
  );

  useEffect(() => {
    if (selectedOption) return; // don't overwrite existing selection
    if (query) return; // only reset to the default value if query isn't set, i.e. user hasn't typed into the field yet
    if (!defaultValue || !bindValue || !options) return; // don't set a default value if it's not provided or if the options aren't loaded yet
    const defaultSelectedOption = options.find(
      (o) => bindValue(o) === defaultValue,
    );
    setSelectedOption(defaultSelectedOption ?? null);
    if (defaultSelectedOption && queryMinLength) {
      setQuery(bindLabel(defaultSelectedOption));
    }
    // this doesn't need to be called when the query or selectedOption changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, bindValue, options]);

  const fallbackOption =
    constructFallbackOption && constructFallbackOption(query);

  function updateSelection(newOption: T) {
    if (fallbackOption && newOption === fallbackOption) {
      void fallbackOption.onSelect(updateSelection, query);
      return;
    }
    setSelectedOption(newOption);

    if (newOption && onChange) {
      onChange(newOption);
    }
  }

  // Validation
  useEffect(() => {
    if (!ref.current?.value) {
      if (!required) ref.current?.setCustomValidity('');
      return;
    }
    if (!selectedOption) {
      ref.current?.setCustomValidity(
        required ? t('pleaseSelectAValue') : t('pleaseSelectAValueOrClear'),
      );
    } else {
      ref.current?.setCustomValidity('');
    }
  }, [query, selectedOption, required, t]);

  return (
    <Combobox
      as="div"
      value={selectedOption}
      onChange={updateSelection}
      disabled={disabled}
    >
      <Combobox.Label className="block text-sm font-medium text-gray-700">
        {label} {required && '*'}
      </Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-accent-500 focus:outline-none focus:ring-1 focus:ring-accent-500 text-sm"
          onChange={debounce((event) => setQuery(event.target.value), 200)}
          displayValue={(option: T) => option && bindLabel(option)}
          autoComplete={undefined}
          ref={ref}
          required={required}
          placeholder={placeholder}
        />
        <Combobox.Button
          className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-[10px] focus:outline-none"
          data-cy={`combobox-button-${label}`}
        >
          {isValidating ? (
            <LoadingIndicator size={4} />
          ) : (
            <div className="flex flex-row items-center">
              {labelContent ? (
                <span className="text-gray-500 shrink-0 flex items-center pr-2 pl-1">
                  {labelContent(selectedOption)}
                </span>
              ) : null}
              <ChevronUpDownIcon
                className="h-5 w-5 stroke-[2.5] text-gray-500"
                aria-hidden="true"
              />
            </div>
          )}
        </Combobox.Button>

        {((options && options?.length > 0) || constructFallbackOption) && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
            {options?.map((option) => (
              <Combobox.Option
                key={bindValue(option)}
                value={option}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-accent-600 text-white' : 'text-gray-900',
                  )
                }
                data-cy={`combobox-option-${label}`}
              >
                {({ active, selected }) => (
                  <div className="flex flex-row items-center">
                    <span
                      className={classNames(
                        'block',
                        selected && 'font-semibold',
                      )}
                    >
                      {bindLabel(option)}
                    </span>

                    {labelContent ? (
                      <span
                        className={classNames(
                          active ? 'text-white' : 'text-gray-500',
                          'shrink-0 flex items-center ml-2 pr-2 pl-1',
                        )}
                      >
                        {labelContent(option)}
                      </span>
                    ) : null}

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-accent-600',
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </div>
                )}
              </Combobox.Option>
            ))}
            {fallbackOption && (
              <Combobox.Option
                key={bindValue(fallbackOption)}
                value={fallbackOption}
                onSelect={() => fallbackOption.onSelect(updateSelection, query)}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-accent-600 text-white' : 'text-gray-900',
                  )
                }
                data-cy={`combobox-option-${label}`}
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        'block',
                        selected && 'font-semibold',
                      )}
                    >
                      {bindLabel(fallbackOption)}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-accent-600',
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            )}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
