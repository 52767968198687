import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import minMax from 'dayjs/plugin/minMax';
dayjs.extend(minMax);
import { t } from 'i18next';
import { ReactChild } from 'react';
import authenticatedFetcher from '../../data/authenticatedFetcher';
import authenticatedPost from '../../data/authenticatedPost';
import { HttpEndpoints } from '../../data/httpEndpoints';
import {
  Color,
  CreateExamAttemptDto,
  Exam,
  ExamAttempt,
  LicenseCategory,
  UpdateExamAttemptDto,
} from '../../typings/backend-types';
import { Tag } from '../Common/Tag';
import { dateInputFormat } from '@components/Forms/FormFields/BasicTextInput';

export interface ExamFormElements {
  licenseCategory: HTMLSelectElement & { value: LicenseCategory };
}

export enum ExamStatus {
  PASSED = 'passed',
  FAILED = 'failed',
}

export function getColorForExamStatus(status: string): Color {
  switch (status) {
    case ExamStatus.PASSED:
      return Color.Green;
    case ExamStatus.FAILED:
      return Color.Red;
    default:
      return Color.Slate;
  }
}

export function examAttemptRadioRenderer(
  option: { id: string; title: ReactChild },
  select: () => void,
  isActive: boolean,
): JSX.Element {
  return (
    <div onClick={select} className="cursor-pointer text-sm font-normal">
      <Tag
        color={isActive ? getColorForExamStatus(option.id) : Color.Slate}
        faint={!isActive}
      >
        {option.title}
      </Tag>
    </div>
  );
}

export function hasPassedTheExam(examAttempts: { passed: boolean }[]): boolean {
  if (!examAttempts) return;
  return !!examAttempts?.find((ea) => ea.passed);
}

export type ExamAttemptCreateOrUpdateInfo = Omit<
  ExamAttempt,
  'organization' | 'id'
> & { id: string | null };

export async function createOrUpdateExamAttempts(
  examAttempts: ExamAttemptCreateOrUpdateInfo[],
  examId: string,
  organizationId: string,
): Promise<void> {
  if (examAttempts.length > 0) {
    await Promise.all(
      examAttempts?.map(async (ea) => {
        if (ea.id) {
          const patchExamAttempt: UpdateExamAttemptDto = {
            attempt_number: ea.attempt_number,
            exam_date: dayjs.utc(ea.exam_date).toISOString(),
            examiner: ea.examiner,
            passed: ea.passed,
            comment: ea.comment,
            exam: { id: examId },
          };
          if (ea.event) delete patchExamAttempt.exam_date;
          await authenticatedPost(
            HttpEndpoints.ExamAttemptEndpoints.patchExamAttempt(
              ea.id,
              organizationId,
            ),
            patchExamAttempt,
            'PATCH',
          );
        } else {
          // Create new exam attempts
          const newExamAttempt: CreateExamAttemptDto = {
            attempt_number: ea.attempt_number,
            exam_date: dayjs.utc(ea.exam_date).toISOString(),
            examiner: ea.examiner,
            passed: ea.passed,
            comment: ea.comment,
            exam: { id: examId },
          };
          await authenticatedPost(
            HttpEndpoints.ExamAttemptEndpoints.postExamAttempt(organizationId),
            newExamAttempt,
          );
        }
      }),
    );
  }
}

export async function deleteExamAttempts(
  examAttemptToDelete: { id: string }[],
  organizationId: string,
): Promise<void> {
  if (examAttemptToDelete.length > 0) {
    await Promise.all(
      examAttemptToDelete.map(async (ea) => {
        await authenticatedFetcher(
          HttpEndpoints.ExamAttemptEndpoints.deleteExamAttempt(
            ea.id,
            organizationId,
          ),
          'DELETE',
        );
      }),
    );
  }
}

export function getStatusTag(exam: Exam): JSX.Element | string {
  if (exam.examAttempts?.find((e) => e.passed)) {
    return (
      <Tag color={Color.Green} className="text-sm">
        {t('exams.passed')}
      </Tag>
    );
  }
  return '-';
}

export function getNumberOfPassedExams(exams: Exam[]): number {
  return exams?.filter((ex) => ex.examAttempts?.find((a) => a.passed))?.length;
}

export function getNextAttemptNumber(examAttempts: ExamAttempt[]): number {
  if (!examAttempts) return;
  if (examAttempts.length === 0) return 1;
  const maxAttemptNumber: number = Math.max(
    ...examAttempts.map((ea) => ea.attempt_number),
  );
  if (!(maxAttemptNumber >= 4)) return maxAttemptNumber + 1;
  return null;
}

/**
 * Returns the earliest valid attempt date for the given attempt number
 * @param examAttempts The list of existing exam attempts
 * @param attemptNumber The attempt number for which the minimum valid date should be computed. If not given, all exam attempts will be taken into account, i.e. this will return the next valid date for a new attempt.
 * @returns The day after the previous exam attempt in date input format
 */
export function getMinExamAttemptDate(
  examAttempts: ExamAttempt[],
  attemptNumber = Number.POSITIVE_INFINITY,
): string {
  if (!examAttempts || examAttempts.length === 0) return undefined;
  const latestExamDate = dayjs.max(
    examAttempts
      .filter((ea) => ea.attempt_number < attemptNumber)
      .map((ea) => dayjs(ea.exam_date)),
  );
  if (latestExamDate == null) return undefined;
  return dayjs(latestExamDate).add(1, 'day').format(dateInputFormat);
}

/**
 * @param examAttempts The list of existing exam attempts
 * @param attemptNumber The attempt number for which the maximum date should be computed. If not given, no maximum date is computed, because the attempt is newly created.
 * @returns the latest valid attempt date for the given attempt number
 */
export function getMaxExamAttemptDate(
  examAttempts: ExamAttempt[],
  attemptNumber?: number,
): string {
  if (!examAttempts || examAttempts.length === 0 || !attemptNumber) {
    return undefined;
  }
  const earliestExamDate = dayjs.min(
    examAttempts
      .filter((ea) => ea.attempt_number > attemptNumber)
      .map((ea) => dayjs(ea.exam_date)),
  );
  if (earliestExamDate == null) return undefined;
  return dayjs(earliestExamDate).subtract(1, 'day').format(dateInputFormat);
}

export const examAttemptStatusOptions: { id: string; title: string }[] = [
  { id: ExamStatus.PASSED, title: t('exams.passed') },
  { id: ExamStatus.FAILED, title: t('exams.failed') },
];
